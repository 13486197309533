<template>
  <div
    :class="$style.container"
    :style="`background-color: ${application.properties.color};background-image:url(${application.properties.background});`"
  >
    <div :class="$style.btnGroup">
      <div>
        <Xbutton @click="handleClick(1)">{{
          application.category
            ? application.category === "PRE_REGISTRATION"
              ? "未登记，点击预登记"
              : "未登记，点击登记"
            : "未登记，点击预登记"
        }}</Xbutton>
      </div>
      <div>
        <Xbutton :isReverse="true" @click="handleClick(2)"
          >已登记，提取确认函</Xbutton
        >
      </div>
    </div>
    <div v-if="page.id">
      <template v-for="pageComponent of page.components">
        <showcase
          v-show="pageComponent.component.key === 'Showcase'"
          :properties="pageComponent.properties"
          :data="pageComponent.data"
          :key="pageComponent.id"
        ></showcase>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Xbutton from "../components/button.vue";
import Showcase from "../components/showcase";

import ApplicationPageService from "../service/application-page";

export default {
  components: { Xbutton,Showcase },
  computed: {
    ...mapState({
      application: (state) => state.application,
      isWeChart: (state) => state.isWeChart,
    }),
  },
  data() {
    return { page: {} };
  },
  methods: {
    handleClick(type) {
      // 点击的时候判断微信的环境2，
      if (type === 1) {
        this.$router.push("/verification");
      }
      if (type === 2) {
        this.$router.push("/verification");
      }
    },
  },
  async mounted() {
     const pages = await ApplicationPageService.getApplicationPages(
      this.application,
      {
        path: this.$route.path,
      }
    );

    console.log(pages);

    if (pages && pages.length > 0) {
      this.page = pages[0];
    }
  }
};
</script>

<style lang="less" module>
.container {
  height: 100vh;
  width: 100%;
  background-size: 100% 100%;
  position: relative;

  .btnGroup {
    position: absolute;
    bottom: 106px;
    width: 100%;
    box-sizing: border-box;
    padding: 25px 70px;

    > div:last-child {
      margin-top: 20px;
    }
  }
}
</style>
